
























import { Component, Vue } from 'vue-property-decorator';
import { H3Icon, H3List } from '@authine/thinking-ui';

import { WorkPlatformService } from '@WorkPlatform/api/index';

import { namespace } from 'vuex-class';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-list',
  components: {
    H3Icon,
    H3List,
    AppItem: () => import('./app-item.vue'),
  },
})
export default class ComponentName extends Vue {
  @workPlatformModule.Action('setOlineApps') setOlineApps: any;

  @workPlatformModule.State('recentApps') recentApps: any;

  onlineAppList: any[] = [];

  mobileAppList: any[] = [];

  page: number = 1;

  finished: boolean = false;

  loading: boolean = false;

  flag: boolean = false;

  /**
   * 滚动加载
   */
  async nextPage () {
    // 控件问题，如果数据没撑满一页的时候会发起两次请求，所以拦截一下
    if (this.flag) {
      return;
    }
    this.flag = true;
    const wait: number = 100;
    setTimeout(() => {
      this.flag = false;
    }, wait);
    // end

    this.loading = true;
    const data = await this.getAppList();
    this.page++;

    this.onlineAppList.push(...data.data);
    this.mobileAppList.push(...(data.data.filter(item => {
      return item.homepages && item.homepages.mobileHomepage;
    })));
    this.setOlineApps(this.onlineAppList);
    this.$emit('triggerAllAppLength', this.mobileAppList.length);
    this.finished = this.onlineAppList.length >= (data.total || 0);
    this.loading = false;
  }

  /**
   * 获取应用列表
   */
  async getAppList () {
    const params: any = {
      keyword: '',
      page: this.page,
      size: 40,
    };

    return WorkPlatformService.getAppList(params).then((res: any) => {
      if (res.success) {
        return res;
      } else {
        return {};
      }
    });
  }

  goToSearch () {
    this.$router.push({
      name: 'm-work-platform-home-search-app',
    });
  }
}
